export const environment = {
  mode: 'development',
  name: 'Marinete',
  organizationId: '66fef3f33c42faed3696a26e',
  languageId: '664ce03a981ee46c14e6d7be',
  googleApiKey: 'AIzaSyCgfHDkUaIccuOsVYhEQfoWmIbs6nqGmhY',
  baseUrl: 'https://dev.marineteapp.com.br',
  shoppinggooUrl: 'https://dev.marineteapp.com.br/shoppinggoo',
  globalgooUrl: 'https://dev.marineteapp.com.br/globalgoo',
  organizationgooUrl: 'https://dev.marineteapp.com.br/organizationgoo',
  servicegooUrl: 'https://dev.marineteapp.com.br/servicegoo',
  routergooUrl: 'https://dev.marineteapp.com.br/routergoo',
};
